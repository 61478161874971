(function () {
  "use strict";

  window.dataLayer = window.dataLayer || [];

  var GoogleTagManager = function () {
    this.listen();
  };

  // private method

  /**
   * set the label
   * 1: try to use the data-gtm-label
   * 2: try the href: without the language otherwise return href
   * 3: return undefined
   */
  function setLabel($target) {
    if ($target.attr("data-gtm-label")) {
      return $target.attr("data-gtm-label");
    } else if ($target.attr("href")) {
      var href = $target.attr("href");

      // check if there is a lang, if so remove it
      // otherwise return the href itself
      if (
        href.startsWith("/en") ||
        href.startsWith("/fr") ||
        href.startsWith("/de") ||
        href.startsWith("/es") ||
        href.startsWith("/it")
      ) {
        // slice the language
        // en/ fr/ de/ ....
        return href.slice(3);
      } else {
        return href;
      }
    }

    return "undefined";
  }

  /**
   * Binds $body element containing a data-gtm-category attribute to send analytics events.
   */
  GoogleTagManager.prototype.listen = function () {
    var $body = $("body");

    // we use a namespace for the case of input change
    // see below

    $body.on(
      "change.gtm_namespace",
      "input[data-gtm-category]",
      this.buildAndSendGenericTag.bind(this)
    );

    $body.on(
      "change.gtm_namespace",
      "select[data-gtm-category]",
      this.buildAndSendGenericTag.bind(this)
    );

    $body.on(
      "mouseup.gtm_namespace",
      "[data-gtm-category]:not(select):not(input)",
      this.buildAndSendGenericTag.bind(this)
    );

    $body.on(
      "mouseup.gtm_namespace",
      'input[type="submit"][data-gtm-category]',
      this.buildAndSendGenericTag.bind(this)
    );
  };

  /**
   * Call only for some form when we need the name of the input + the value....
   * ex: notifications.field, preferences.field ....
   * use it by adding the data attribute -> data_gtm_change_value on the input
   * ex: notification_preferences.html
   * IMPORTANT
   * you need to add on the page where you want the label to be changed when the input change this javascript:
   * window.GoogleTagManager.listenOnInputChanges();
   */
  GoogleTagManager.prototype.listenOnInputChanges = function () {
    // remove the delegate so that we go first here and change the value of the data before sending the value
    // -- little hack ;)
    // -- we use a namespace to only remove the changes delegates created above
    $("body").off("change.gtm_namespace");

    // event that will be call everytime the input is going to change
    $("body").on(
      "change",
      "[data-gtm-change-value]",
      this.handleChangeEventInput.bind(this)
    );
  };

  /**
   * Change the value of the label based on the value of the input
   * main purpose is for form input
   */
  GoogleTagManager.prototype.handleChangeEventInput = function (e) {
    var $input = $(e.currentTarget);

    // get the value of the label
    // first time the value will be just the label alone
    // then will be the label + " - XXX"
    var label = $input.attr("data-gtm-label");

    // remove what is after the -
    // so we only keep what was in the label at first
    if (label.indexOf(" -") !== -1) {
      label = label.split(" -")[0];
    }

    // specific case when the input is a checkbox
    // input.val() would give "on".. always even when not checked..
    // so we need to use is(":checked")
    if ($input.is("input[type='checkbox']")) {
      // should be only true or false
      var inputValue = $input.is(":checked");

      // format the input value to either 1 or 0
      if (inputValue == "on" || inputValue == true || inputValue == "true") {
        inputValue = 1;
      } else {
        inputValue = 0;
      }

      // format the new label
      label = label + " - " + inputValue;
    } else {
      // value on the input
      var inputValue = $input.val();
      // format the new label
      label = label + " - " + inputValue;
    }

    // set the label with the new one
    $input.attr("data-gtm-label", label);

    // call the send tag
    // IMPORTANT
    // we absolutely need this line to generate and send the tag
    this.buildAndSendGenericTag(e);
  };

  /**
   *
   */
  GoogleTagManager.prototype.buildAndSendGenericTag = function (e) {
    if (!e) {
      console.warn(
        "You need to pass an event to use the method. Otherwise use sendTag with the correct arguments."
      );
      return;
    }

    var vars = {};

    var $target = $(e.currentTarget);

    // if the attribute doesn't exist the value will be either undefined or false
    // in this case set the field with undefined
    var category = $target.attr("data-gtm-category") || "undefined";
    var action = $target.attr("data-gtm-action") || "undefined";
    var label = setLabel($target); // need a method because we are doing more setup that other field
    var value = $target.attr("data-gtm-value") || "undefined";

    // set the category(name) of the event
    if (category != "undefined") {
      vars.category = category;
    } else {
      console.warn(
        "the element clicked does NOT have the required attribut: data-gtm-category"
      );
      return;
    }

    // set the action
    // use the data attribut if exist otherwise the href without the lang
    if (action != "undefined") {
      vars.action = action;
    } else {
      console.warn(
        "the element clicked does NOT have the required attribut: data-gtm-action"
      );
      return;
    }

    // set the label
    vars.label = label;

    // set the value
    vars.value = value;

    // send var
    this.sendTag(vars);
  };

  /**
    *
    * ex:
      window.GoogleTagManager.sendTag({
         category: "account",
         action: "events.priority",
         label: $newChecked.data('checkbox-filter')
      })
    */
  GoogleTagManager.prototype.sendTag = function (vars) {
    if (!vars.category) {
      console.warn(
        "The required following attribute needs to be set: category"
      );
      return;
    }

    // use by google tag manager to link that custom event to a tag
    vars.event = "sculpteoEvent";

    // for debug only.
    /*
      // log for staff user
      if (typeof window.User !== undefined && window.User.isStaff) {
         console.log("%cGoogle Tag Manager:", "font-weight:bold;font-size:14px;");
         console.log("%c  Category = ", "font-weight: bold", vars.category);
         console.log("%c  Action =   ", "font-weight: bold", vars.action);
         console.log("%c  Label =    ", "font-weight: bold", vars.label);
         console.log("%c  Value =    ", "font-weight: bold", vars.value);
         // used as a separator
         console.log(" ");
      }
      */
    dataLayer.push(vars);
  };

  GoogleTagManager.prototype.pageView = function (url) {
    var vars = {
      event: "sculpteoCustomPageViewEvent",
      customPageView: url,
    };
    dataLayer.push(vars);
  };

  window.GoogleTagManager = new GoogleTagManager();
})();
