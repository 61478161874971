/**
 * Hide the newsletter popin
 *
 */
function hideNewsletterPopin() {
  $(".newsletter-popin-subscribe", this.$el).hide();
  $(".newsletter-popin-unsubscribe", this.$el).hide();
}

function submitForm(url, email) {
  $.ajax({
    type: "POST",
    url: url,
    data: { email: email },
    success: function (msg) {
      hideNewsletterPopin();
      var newsletter_block = $("[data-newsletter-subscribe-state]");
      newsletter_block.attr(
        "data-newsletter-subscribe-state",
        newsletter_block.attr("data-newsletter-subscribe-state") == "false"
          ? true
          : false
      );
    },
    error: function (XMLHttpRequest, textStatus, errorThrown) {
      // display errors
      $("#newsletter-errors").addClass("error");
      $("#newsletter-errors").text(XMLHttpRequest.responseText);
      console.log(XMLHttpRequest.responseText);
    },
  });
}

function subscribeEmail() {
  var email = $("#field_email").val();
  var agree_checkbox = $("#newsletter_agree_checkbox");

  // Check if user has agreed to subscribe.
  if (agree_checkbox.is(":checked")) {
    submitForm(URLS["newsletter_subscribe"], email);
    var _hsq = (window._hsq = window._hsq || []);
    _hsq.push([
      "identify",
      {
        email: email,
      },
    ]);
    _hsq.push([
      "trackEvent",
      {
        id: "Newsletter Subscribe",
      },
    ]);
  } else {
    $("label[for='" + agree_checkbox.attr("id") + "']")
      .css("text-decoration", "underline")
      .css("color", "red");
  }
}

function unsubscribeEmail() {
  var email = $("#unsub_field_email").val();
  submitForm(URLS["newsletter_unsubscribe"], email);
}

$().ready(function () {
  "use strict";

  $("[data-newsletter-popin-link]").on("click", function () {
    $(".newsletter-popin-subscribe").show();
    $("#field_email").val($("#inputNewsletter").val());
  });
  $("[data-newsletter-popin-unsubscribe-link]").on("click", function () {
    $(".newsletter-popin-unsubscribe").show();
    $("#unsub_field_email").val($("#inputNewsletter").val());
  });
  this.$el = $(".newsletter-popin");

  $("#subscribeform").submit(function (event) {
    event.preventDefault();
    subscribeEmail();
  });
  $("#unsubscribeform").submit(function (event) {
    event.preventDefault();
    unsubscribeEmail();
  });

  $(document).on("click", ".modal-back", function () {
    hideNewsletterPopin();
  });
});
